import { useCallback, useMemo } from "react"
import ReactServer from "react-dom/server"

import { fromUnixTime } from "date-fns"
import * as Highcharts from "highcharts"
import HighchartsExportData from "highcharts/modules/export-data"
import HighchartsExport from "highcharts/modules/exporting"
import HighchartsReact from "highcharts-react-official"

import { THome } from "src/data/homes/types/homeTypes"
import { TClockType, TUser } from "src/data/user/user"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import { ChartTooltip } from "src/ui/Graphs/ChartTooltip"
import { TBarChartData } from "src/ui/Graphs/graphTypes"
import { formatDate } from "src/utils/l10n"

HighchartsExport(Highcharts)
HighchartsExportData(Highcharts)

export type BarChartProps = {
  data: TBarChartData
  canExport?: boolean
  zoom?: "x" | "xy" | "y"
  timezone: THome["timezone"]
  clockType: TClockType
  tooltip: {
    unit: string
    decimals?: number
    formatter?: (context: {
      date: Date
      value: number
      min?: number
      max?: number
    }) => React.ReactElement
  }
}

export function BarChart({
  data,
  canExport = false,
  zoom = "x",
  tooltip,
  timezone,
  clockType,
}: BarChartProps) {
  const { t } = useTranslate()

  const series: Highcharts.Options["series"] = useMemo(() => {
    const s: Highcharts.Options["series"] = [
      {
        type: "column",
        showInLegend: false,
        data: data.map(([x, y]) => [fromUnixTime(x).getTime(), y]),
        borderRadius: 6,
        color: mColors.primary,
        groupPadding: 0,
      },
    ]
    return s
  }, [data])

  const tooltipFormatter = useCallback(
    (context: Highcharts.TooltipFormatterContextObject) => {
      if (!context.x || !context.y) return false

      if (tooltip.formatter) {
        return ReactServer.renderToString(
          tooltip.formatter({
            date: new Date(context.x),
            value: context.y,
          })
        )
      }

      const date = formatDate({
        date: new Date(context.x).toISOString(),
        clockType,
        timezone,
      })
      const decimals = tooltip.decimals ?? 1

      const contentLabel = t(langKeys.value)

      return ReactServer.renderToString(
        <ChartTooltip
          date={date}
          label={contentLabel}
          value={context.y.toFixed(decimals)}
          unit={tooltip.unit}
        />
      )
    },
    [clockType, t, timezone, tooltip]
  )

  const options = useMemo<Highcharts.Options>(
    () => ({
      accessibility: { enabled: false },
      credits: { enabled: false },
      title: { text: "" },
      xAxis: {
        labels: { style: axisLabelStyle },
        lineColor: mColors.divider,
        tickColor: mColors.divider,
        title: { text: "" },
        type: "datetime",
        tickAmount: 8,
        dateTimeLabelFormats: getXAxisDateTimeLabelFormat(clockType),
      },
      yAxis: {
        labels: { style: axisLabelStyle },
        title: { text: "" },
        endOnTick: true,
        startOnTick: true,
        allowDecimals: false,
        type: "linear",
      },
      time: { timezone },
      chart: {
        type: "column",
        zooming: { type: zoom },
      },
      exporting: {
        enabled: canExport,
        csv: {},
      },
      series,
      tooltip: {
        useHTML: true,
        padding: 0,
        shared: true,
        shadow: false,
        outside: true,
        formatter() {
          return tooltipFormatter(this)
        },
      },
    }),
    [canExport, series, timezone, zoom, tooltipFormatter, clockType]
  )

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

const axisLabelStyle: Highcharts.CSSObject = {
  fontFamily: "'Figtree', sans-serif",
  fontSize: "12px",
  color: mColors.textTertiary,
}

/** https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats */
const defaultDateTimeLabelFormat = {
  millisecond: "%H:%M:%S.%L",
  second: "%H:%M:%S",
  minute: "%H:%M",
  hour: "%H:%M",
  day: "%e %b",
  week: "%e %b",
  month: "%b '%y",
  year: "%Y",
} as const

function getXAxisDateTimeLabelFormat(
  clockType: TUser["clock_type"]
): Highcharts.AxisDateTimeLabelFormatsOptions {
  const use12hClock = clockType === "12"
  if (use12hClock) {
    return {
      ...defaultDateTimeLabelFormat,
      hour: `%I:%M %p`,
      day: "%a %e %b",
    }
  }
  return defaultDateTimeLabelFormat
}
